<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs">
      <template v-slot:button>
          <v-btn
            height="42"
            width="147"
            color="primary"
            class="rounded-pill mr-6"
            style="text-transform: none !important"
            @click="$router.push({ name: 'NewNote' })"
          >
            Crear nota
          </v-btn>
        </template>
    </BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <TheNotesDataTable />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex"

export default {
  components: {
    TheNotesDataTable: () =>
      import(
        "@/components/dashboardComponents/TheNotesDataTable"
      )
  },
  data: () => ({
    reload: false,
    create: false,
    page: {
      title: "Lista de notas"
    },
    elementVisible: false,
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        to: "/dashboard/index/"
      },
      {
        text: "Notas",
        disabled: true
      }
    ]
  }),
  methods: {
    ...mapMutations(['SET_SEARCH'])
  }
};
</script>
